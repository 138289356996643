import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {isArray} from 'lodash-es';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import {Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '@mui/system/styled';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import CustomTooltip from 'components/elements/CustomTooltip';
import useEvidenceFeedbackCreate from 'hooks/evidences/useEvidenceFeedbackCreate';
import useEvidenceFeedbacks from 'hooks/evidences/useEvidenceFeedbacks';

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function haveSameKeys(arr) {
    if (!Array.isArray(arr) || arr.length === 0) return false;

    const firstKeys = Object.keys(arr[0]);

    return arr.every(obj => {
        const currentKeys = Object.keys(obj);
        return firstKeys.length === currentKeys.length &&
            firstKeys.every(key => currentKeys.includes(key));
    });
}

function isObjectArray(arr) {
    return Array.isArray(arr) && arr.every(item => typeof item === 'object' && item !== null);
}

const EvidenceItem = ({
                          name, value, tooltip, feedbacks = [], feedback = false, submitFeedback = () => {
    }, id
                      }) => {

    const isSubmitted = () => {
        let index = feedbacks.findIndex(f => f.feedback_type === id)
        if (index >= 0) {
            return feedbacks[index].feedback
        } else {
            return 'NONE'
        }
    }

    return <Grid container item xs={12}>
        <Grid item xs={12} container alignItems={'center'} spacing={0.5}>
            <Grid item>
                <Typography sx={{
                    fontSize: '0.95rem',
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 1)',
                    textTransform: 'capitalize'
                }}>{name}
                    {tooltip && <span
                        style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                    <CustomTooltip title={tooltip}>
                        <InfoOutlinedIcon
                            sx={{fontSize: '0.875rem'}}/>
                    </CustomTooltip>
                </span>}

                    <span style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                <CopyToClipboard text={value}>
                    <CustomTooltip title={'Copy'}>
                    <IconButton sx={{padding: '0.1em'}}>
                <ContentCopyIcon sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                        </CustomTooltip>
                </CopyToClipboard>
                </span>
                    {feedback && <>
                <span
                    style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                    <FeedbackButton submitFeedback={submitFeedback} type={'GOOD'} id={id} title={'Good Response'}
                                    submitted={isSubmitted()}/>
                </span>
                        <span
                            style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                            <FeedbackButton submitFeedback={submitFeedback} type={'BAD'} id={id} title={'Bad Response'}
                                            submitted={isSubmitted()}/>
                </span>
                    </>}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem'}}>{value}
            </Typography>
        </Grid>
    </Grid>
}

const EvidenceOutcomeItem = ({
                                 name,
                                 feedbacks = [],
                                 feedback = false,
                                 extracted_evidences,
                                 submitFeedback = () => {
                                 },
                                 id
                             }) => {
    const headers = ['Outcome Variable', 'Metric Name', 'Effect Sizes', 'P Value', 'Confidence Interval']

    const isSubmitted = () => {
        let index = feedbacks.findIndex(f => f.feedback_type === id)
        if (index >= 0) {
            return feedbacks[index].feedback
        } else {
            return 'NONE'
        }
    }

    return <Grid container item xs={12}>
        <Grid item xs={12} container alignItems={'center'} spacing={0.5}>
            <Grid item>
                <Typography sx={{
                    fontSize: '0.95rem',
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 1)',
                    textTransform: 'capitalize'
                }}>{name}

                    {feedback && <>
                <span
                    style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                    <FeedbackButton submitFeedback={submitFeedback} type={'GOOD'} id={id} title={'Good Response'}
                                    submitted={isSubmitted()}/>
                </span>
                        <span
                            style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                            <FeedbackButton submitFeedback={submitFeedback} type={'BAD'} id={id} title={'Bad Response'}
                                            submitted={isSubmitted()}/>
                </span>
                    </>}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{padding: '0 8px 8px 2px !important'}}>
            {isObjectArray(extracted_evidences['Outcome Metrics']) && haveSameKeys(extracted_evidences['Outcome Metrics']) && <>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            {headers.map((key) =>
                                <TableCell><span style={{
                                    fontWeight: '700',
                                    textTransform: 'capitalize'
                                }}>{key}</span></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody id="data-table">
                        {Array.from(extracted_evidences['Outcome Metrics']).map(item =>
                            <StyledTableRow>
                                <TableCell>
                                    <RenderTableCellValues value={item['outcome_variable']}
                                                           tooltip={item['outcome_variable_reasoning']}/>
                                </TableCell>
                                <TableCell>
                                    <RenderTableCellValues value={item['metric_name']}
                                                           tooltip={item['metric_name_reasoning']}/>
                                </TableCell>
                                <TableCell>
                                    <RenderTableCellValues value={item['effect_sizes']}
                                                           tooltip={item['effect_sizes_reasoning']}/>
                                </TableCell>
                                <TableCell>
                                    <RenderTableCellValues value={item['p_value']}
                                                           tooltip={item['p_value_reasoning']}/>
                                </TableCell>
                                <TableCell>
                                    <RenderTableCellValues value={item['confidence_interval']}
                                                           tooltip={item['confidence_interval_reasoning']}/>
                                </TableCell>
                            </StyledTableRow>
                        )}

                    </TableBody>
                </Table>
            </>}
        </Grid>

    </Grid>
}

const FeedbackButton = ({title, type, id, submitFeedback, submitted = 'NONE'}) => {
    return (<>{submitted === 'NONE' ? <CustomTooltip title={title}>
                {type === 'BAD' && <IconButton sx={{padding: '0.1em'}} onClick={() => submitFeedback(id, 'BAD')}>
                    <ThumbDownOffAltIcon sx={{fontSize: '1.125rem'}}/>
                </IconButton>
                }
                {type === 'GOOD' && <IconButton sx={{padding: '0.1em'}} onClick={() => submitFeedback(id, 'GOOD')}>
                    <ThumbUpOffAltIcon sx={{fontSize: '1.125rem'}}/>
                </IconButton>
                }
            </CustomTooltip> :
            <>
                {type === 'GOOD' && submitted === 'GOOD' &&
                    <CustomTooltip title={title}>
                        <IconButton sx={{padding: '0.1em'}}><ThumbUpAltIcon
                            sx={{fontSize: '1.125rem'}}/></IconButton></CustomTooltip>}
                {type === 'BAD' && submitted === 'BAD' &&
                    <CustomTooltip title={title}>
                        <IconButton sx={{padding: '0.1em'}}><ThumbDownAltIcon sx={{fontSize: '1.125rem'}}/></IconButton></CustomTooltip>}
            </>
        }
        </>
    )
}

const GptResponse = ({value}) => {
    const [details, setDetails] = useState(false)
    const handleViewDetails = () => {
        setDetails((prevState) => !prevState)
    }
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" sx={{textTransform: 'none'}} onClick={handleViewDetails}>
                        {details ? 'Hide' : 'Details'}
                    </Button>
                }
            >
                Failed to extract evidence
            </Alert>
        </Grid>
        {details && <Grid item xs={12}>
            <Alert icon={false} severity="warning">
                {value}
            </Alert>
        </Grid>
        }
    </Grid>
}

const RenderObject = ({item}) => {
    return <>
        {item !== null && typeof item !== 'object' && !isArray(item) && item}
        {item !== null && typeof item === 'object' && !isArray(item) && <>
            {Object.keys(item).map((key) => <>
                {item[key] !== null && typeof item[key] === 'object' && !isArray(item[key]) && <>
                    <RenderObject item={item[key]}/>
                </>}
                {item[key] !== null && typeof item[key] !== 'object' && !isArray(item[key]) && <>
                    <RenderObjectValues item={key} value={item[key]}/>
                </>}
            </>)}
        </>}
    </>
}

const RenderObjectValues = ({item, value}) => {
    return <span style={{paddingRight: '1em'}}><span style={{
        fontWeight: '700',
        textTransform: 'capitalize'
    }}>{item}</span>: {value}</span>
}

const RenderTableCellValues = ({value, tooltip}) => {
    return <CustomTooltip title={tooltip}>
        <span style={{cursor: 'pointer'}}>{value === null ? 'None' : value}</span>
    </CustomTooltip>
}

const OutcomeItem = ({name, value, tooltip}) => {

    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Typography sx={{fontSize: '0.875rem',}}>

                {typeof value !== 'undefined' && typeof value !== 'object' && !isArray(value) &&
                    <span>
                        <span style={{
                            fontWeight: '700',
                            color: '#797979',
                            textTransform: 'capitalize'
                        }}>{name}{tooltip && <span
                            style={{
                                paddingLeft: '0.5em',
                                paddingRight: '0.5em',
                                verticalAlign: 'middle',
                                cursor: 'pointer'
                            }}>
                    <CustomTooltip title={tooltip}>
                        <InfoOutlinedIcon
                            sx={{fontSize: '0.875rem'}}/>
                    </CustomTooltip>
                </span>}: </span>
                        {value}
                        <span
                            style={{paddingLeft: '0.5em', verticalAlign: 'middle', cursor: 'pointer'}}>
                    <CopyToClipboard text={value}>
                    <IconButton sx={{padding: '0.1em'}}>
                        <ContentCopyIcon sx={{fontSize: '0.875rem'}}/>
                    </IconButton>
                    </CopyToClipboard>
                    </span>
                </span>
                }
                {typeof value === 'object' && !Array.isArray(value) && value !== null && <>
                    <span style={{
                        fontWeight: '700',
                        color: '#797979',
                        textTransform: 'capitalize'
                    }}>{name}<br/></span>
                    <Grid container columnSpacing={3} rowSpacing={0.5}>
                        {Object.keys(value).map((key) => {
                            return <Grid container item xs={6} justifyContent={'space-between'}>
                                <Grid item> <span style={{
                                    fontWeight: '700',
                                    textTransform: 'capitalize'
                                }}>{key}</span>
                                </Grid><Grid item> {value[key]}</Grid></Grid>
                        })}
                    </Grid>
                    {/*{JSON.stringify(value, null, '')}*/}
                </>}

                {isArray(value) && <>
                    <span style={{
                        fontWeight: '700',
                        textTransform: 'capitalize'
                    }}>{name}<br/></span>

                    {isObjectArray(value) && haveSameKeys(value) && <>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    {Object.keys(value[0]).map((key) => <TableCell><span style={{
                                        fontWeight: '700',
                                        textTransform: 'capitalize'
                                    }}>{key}</span></TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody id="data-table">
                                {Array.from(value).map(item => <TableRow>
                                        {Object.keys(item).map((key) => <TableCell>
                                            <RenderObject item={item[key]}/>
                                        </TableCell>)}
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </>}

                    {isObjectArray(value) && !haveSameKeys(value) && Array.from(value).map(item => {
                        if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
                            return <>
                                {Object.keys(item).map((key) => {
                                    return <RenderObjectValues item={key} value={item[key]}/>
                                })}
                                <br/> </>
                        } else {
                            return <>
                            </>
                        }
                    })}

                    {!isObjectArray(value) && !haveSameKeys(value) && JSON.stringify(value, null, '')}
                </>}
            </Typography>
        </Grid>
    </Grid>
}

const OutcomeRenderer = ({outcome, index = 0, length = 0}) => {
    const variables = [
        {
            name: 'outcome_variable',
            keys: [
                "outcome_variable", "Outcome variable", "outcome variable", "Outcome_variable", "outcome"
            ]
        },
        {
            name: 'metric_name',
            keys: [
                "metric name", "metric_name", "Metric name", "Metric_name"
            ]
        },
        {
            name: 'effect_sizes',
            keys: [
                "effect sizes", "effect_sizes", "Effect sizes", "effect_size", "Effect_size"
            ]
        },
        {
            name: 'effect_sizes_post',
            keys: [
                "effect sizes post", "effect_sizes_post", "Effect sizes post"
            ]
        },
        {
            name: 'effect_sizes_pre',
            keys: [
                "effect sizes pre", "effect_sizes_pre", "Effect sizes pre"
            ]
        },
        {
            name: 'p_value',
            keys: [
                "p_value", "p-value", "P_value"
            ]
        },
        {
            name: 'confidence_interval',
            keys: [
                "confidence interval", "confidence_interval", "Confidence_interval"
            ]
        },
    ]

    return <>
        {variables.map(variable => (<>
                {variable.name === 'outcome_variable' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        name={'Outcome Variable'} value={outcome[key]} tooltip={outcome[`${key}_reasoning`]}/>)}

                {variable.name === 'metric_name' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'Metric Name'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'Effect Sizes'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_post' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'Effect Sizes Post'} value={outcome[key]}/>)}

                {variable.name === 'effect_sizes_pre' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'Effect Sizes Pre'} value={outcome[key]}/>)}

                {variable.name === 'p_value' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'P Value'} value={outcome[key]}/>)}

                {variable.name === 'confidence_interval' && variable.keys.map((key) => typeof outcome[key] !== 'undefined' &&
                    <OutcomeItem
                        tooltip={outcome[`${key}_reasoning`]}
                        name={'Confidence Interval'} value={outcome[key]}/>)}
            </>)
        )}

        <Grid item xs={12}>
            <Divider sx={{borderBottom: '0.15em solid rgb(233, 233, 233)'}}/>
        </Grid>
    </>
}

const OutcomeMetrics = ({data}) => {

    return <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.95rem',
                fontWeight: '700',
                color: 'rgba(0, 0, 0, 1)',
            }}>Outcome Metrics</Typography>
        </Grid>
        <Grid item xs={12} container spacing={1}>
            {isArray(data) && data.map((outcome, index) => {
                return <OutcomeRenderer outcome={outcome} index={index}/>
            })}
            {typeof data === 'object' && !isArray(data) && <OutcomeRenderer outcome={data}/>}
        </Grid>
    </Grid>
}

const EvidenceDetails = ({evidence}) => {
    const evidenceId = evidence.evidence_id
    const [feedbacks, setFeedbacks] = useState([])
    const {mutateAsync, status} = useEvidenceFeedbackCreate();
    const {data} = useEvidenceFeedbacks({evidenceId})

    useEffect(() => {
        if (data) {
            setFeedbacks(data.data)
        }
    }, [data])

    const handleSubmit = (feedback_type, feedback) => {
        const req = {
            feedback_type,
            feedback
        }
        mutateAsync({req, evidenceId}).then((res) => {
            setFeedbacks([...feedbacks,res.data])
        });
    }

    return <Grid item xs={12} container>
        {["CHAT_GPT_RA_V2","CHAT_GPT_RA_V3_1","CHAT_GPT_RA_V3_2"].includes(evidence['extraction_type']) &&
            <EvidenceViewGPTRAV2 extracted_evidences={evidence['extracted_evidences']} feedbacks={feedbacks} feedback
                                 submitFeedback={handleSubmit}/>}
        {evidence['extraction_type'] === "CHAT_GPT_KOR"  &&
            <EvidenceViewGPTRAV2 extracted_evidences={evidence['extracted_evidences']}/>}
        { !["CHAT_GPT_RA_V2","CHAT_GPT_KOR","CHAT_GPT_RA_V3_1","CHAT_GPT_RA_V3_2"].includes(evidence['extraction_type']) &&
            <EvidenceView extracted_evidences={evidence['extracted_evidences']}/>}
    </Grid>


}

const EvidenceView = ({extracted_evidences}) => {
    const keys = ['research_objective', 'study_design', 'treatment_used', 'population_characteristics', 'population', 'outcome_metrics']
    return <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
        {isArray(extracted_evidences) && extracted_evidences.map(evidence => (Object.keys(evidence).map((key, index) => {
                if (key === 'GPT Response') {

                } else {
                    return <Grid item xs={12}>
                        <EvidenceItem name={key} value={evidence[key]}/>
                    </Grid>
                }

            })
        ))}

        {!isArray(extracted_evidences) &&
            <>
                {Object.keys(extracted_evidences).includes('GPT Response') ?
                    <GptResponse value={extracted_evidences['GPT Response']}/> :
                    <>
                        {
                            extracted_evidences['research_objective'] && typeof extracted_evidences['research_objective'] === 'string' &&
                            <EvidenceItem name={'Objective'}
                                          value={extracted_evidences['research_objective']}/>
                        }
                        {
                            extracted_evidences['study_design'] && typeof extracted_evidences['study_design'] === 'string' &&
                            <EvidenceItem name={'Study Design'}
                                          value={extracted_evidences['study_design']}/>
                        }
                        {
                            extracted_evidences['treatment_used'] && typeof extracted_evidences['treatment_used'] === 'string' &&
                            <EvidenceItem name={'Treatment'}
                                          value={extracted_evidences['treatment_used']}/>
                        }
                        {
                            extracted_evidences['population_characteristics'] && typeof extracted_evidences['population_characteristics'] === 'string' &&
                            <EvidenceItem name={'Population Characteristics'}
                                          value={extracted_evidences['population_characteristics']}/>
                        }
                        {
                            extracted_evidences['population'] && typeof extracted_evidences['population'] === 'string' &&
                            <EvidenceItem name={'Population'}
                                          value={extracted_evidences['population']}/>
                        }

                        {
                            extracted_evidences['outcome_metrics'] &&
                            <OutcomeMetrics
                                data={extracted_evidences['outcome_metrics']}/>
                        }

                        {
                            extracted_evidences['Outcome Metrics'] &&
                            <OutcomeMetrics
                                data={extracted_evidences['Outcome Metrics']}/>
                        }

                        {Object.keys(extracted_evidences).map((key, index) => {
                            return <>
                                {typeof extracted_evidences[key] !== 'object' && extracted_evidences[key] !== null && !keys.includes(key)
                                    && typeof extracted_evidences[key] === 'string' && <Grid item xs={12}>
                                        <EvidenceItem name={key}
                                                      value={extracted_evidences[key]}/>
                                    </Grid>}
                            </>
                        })}
                    </>
                }
            </>
        }
    </Grid>
}

const EvidenceViewGPTRAV2 = ({extracted_evidences, feedbacks = [], submitFeedback,feedback=false}) => {

    return <Grid item xs={12} container spacing={1} sx={{height: 'fit-content'}}>
        {isArray(extracted_evidences) && extracted_evidences.map(evidence => (Object.keys(evidence).map((key, index) => {
                if (key === 'GPT Response') {

                } else {
                    return <Grid item xs={12}>
                        <EvidenceItem name={key} value={evidence[key]}/>
                    </Grid>
                }

            })
        ))}

        {!isArray(extracted_evidences) &&
            <>
                {Object.keys(extracted_evidences).includes('GPT Response') ?
                    <GptResponse value={extracted_evidences['GPT Response']}/> :
                    <>
                        {
                            extracted_evidences['Research Objective'] && typeof extracted_evidences['Research Objective'] === 'string' &&
                            <EvidenceItem name={'Objective'}
                                          feedback={feedback}
                                          feedbacks={feedbacks}
                                          id={'OBJECTIVE'}
                                          submitFeedback={submitFeedback}
                                          value={extracted_evidences['Research Objective']}
                                          tooltip={extracted_evidences['Research Objective Reasoning']}/>
                        }
                        {
                            extracted_evidences['Study Design'] && typeof extracted_evidences['Study Design'] === 'string' &&
                            <EvidenceItem name={'Study Design'}
                                          feedback={feedback}
                                          id={'STUDY_DESIGN'}
                                          feedbacks={feedbacks}
                                          submitFeedback={submitFeedback}
                                          tooltip={extracted_evidences['Study Design Reasoning']}
                                          value={extracted_evidences['Study Design']}/>
                        }
                        {
                            extracted_evidences['Treatment Used'] && typeof extracted_evidences['Treatment Used'] === 'string' &&
                            <EvidenceItem name={'Treatment'}
                                          feedback={feedback}
                                          id={'TREATMENT'}
                                          feedbacks={feedbacks}
                                          submitFeedback={submitFeedback}
                                          tooltip={extracted_evidences['Treatment Used Reasoning']}
                                          value={extracted_evidences['Treatment Used']}/>
                        }
                        {
                            extracted_evidences['Population Characteristics'] && typeof extracted_evidences['Population Characteristics'] === 'string' &&
                            <EvidenceItem name={'Population Characteristics'}
                                          feedback={feedback}
                                          feedbacks={feedbacks}
                                          id={'POPULATION_CHARACTERISTICS'}
                                          submitFeedback={submitFeedback}
                                          tooltip={extracted_evidences['Population Characteristics Reasoning']}
                                          value={extracted_evidences['Population Characteristics']}/>
                        }
                        {
                            extracted_evidences['Population'] && typeof extracted_evidences['Population'] === 'string' &&
                            <EvidenceItem name={'Population'}
                                          feedback={feedback}
                                          feedbacks={feedbacks}
                                          id={'POPULATION'}
                                          submitFeedback={submitFeedback}
                                          tooltip={extracted_evidences['Population Reasoning']}
                                          value={extracted_evidences['Population']}/>
                        }

                        <EvidenceOutcomeItem name={'Outcome Metrics'}
                                             feedback={feedback}
                                             feedbacks={feedbacks}
                                             id={'OUTCOME_METRICS'}
                                             submitFeedback={submitFeedback}
                                             extracted_evidences={extracted_evidences}/>
                    </>
                }
            </>
        }

    </Grid>
}

const ExtractionReview = ({extracted_evidences}) => {

    return <Grid container spacing={1} sx={{height: 'fit-content'}}>

        {
            extracted_evidences['Challenges In Extraction'] && typeof extracted_evidences['Challenges In Extraction'] === 'string' &&
            <EvidenceItem name={'Challenges In Extraction'}
                          value={extracted_evidences['Challenges In Extraction']}/>
        }
        {
            extracted_evidences['Data Quality Assessment'] && typeof extracted_evidences['Data Quality Assessment'] === 'string' &&
            <EvidenceItem name={'Data Quality Assessment'}
                          value={extracted_evidences['Data Quality Assessment']}/>
        }
        {
            extracted_evidences['Helpful Sections'] && typeof extracted_evidences['Helpful Sections'] === 'string' &&
            <EvidenceItem name={'Helpful Sections'}
                          value={extracted_evidences['Helpful Sections']}/>
        }
    </Grid>
}

export {EvidenceDetails, ExtractionReview}