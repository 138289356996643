import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText, MenuList, Popover} from '@mui/material';
import Grid from '@mui/material/Grid';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import UploadIcon from '@mui/icons-material/Upload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useTranslation} from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import CustomTooltip from 'components/elements/CustomTooltip';
import CustomButton from 'components/elements/CustomButton';

const HeaderActions = ({
                           handleTrain,
                           handleTrigger,
                           handleAddSearch,
                           handleImportWithPDF,
                           handleAdd,
                           handleDownloadCSV,
                           handleRefresh,
                    }) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Grid item container xs={12} justifyContent={'space-between'}>
                <Grid item container xs={8} lg={4} spacing={3}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<ModelTrainingIcon sx={{color: '#49ac43'}}/>}
                                      disableRipple
                                      onClick={handleTrain}>{t('Train Classifier')}</CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<PlayArrowIcon sx={{color: '#49ac43'}}/>}
                                      disableRipple
                                      onClick={handleTrigger}>{t('Classify Papers')}</CustomButton>
                    </Grid>
                </Grid>

                <Grid item container xs={6} lg={8} justifyContent={'flex-end'} spacing={3} sx={{display: {sm:'none',xs:'none',md: 'none', lg: 'flex'}}}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<ManageSearchIcon/>} disableRipple
                                      onClick={handleAddSearch}>{t('inbox.newSearch')}</CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} disableRipple
                                      startIcon={<UploadIcon/>}
                                      onClick={handleImportWithPDF}>{t('Import URLs')}</CustomButton>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <MuiButton sx={{color: '#656565'}}*/}
                    {/*               startIcon={<UploadIcon/>}>{t('Import CSV')}</MuiButton>*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<PostAddIcon/>}
                                      onClick={handleAdd}>{t('New Paper')}</CustomButton>
                    </Grid>

                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<GetAppIcon/>} // Use the download icon
                                      onClick={handleDownloadCSV}>
                            {t('Download CSV')}
                        </CustomButton>
                    </Grid>

                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<RefreshIcon sx={{color: '#0794d3'}}/>}
                                      disableRipple
                                      onClick={handleRefresh}>{t('Refresh')}</CustomButton>
                    </Grid>
                </Grid>

                <Grid item container xs={4} justifyContent={'flex-end'} spacing={1} sx={{display: {md: 'flex', lg: 'none'}}}>
                    <Grid item>
                        <CustomButton sx={{color: '#656565'}} startIcon={<ManageSearchIcon/>} disableRipple
                                      onClick={handleAddSearch}>{t('inbox.newSearch')}</CustomButton>
                    </Grid>
                    <Grid item>
                        <CustomTooltip title={t('Refresh')}>
                            <IconButton onClick={handleRefresh}>
                                <RefreshIcon/>
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                    <Grid item>
                        <CustomTooltip title={t('More Actions')}>
                        <IconButton onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                        </CustomTooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}
                     sx={{padding:'1em'}}
                     onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem onClick={(e) => {
                        handleClose()
                        handleImportWithPDF();
                    }}
                              sx={{fontSize: '0.875rem'}}>
                        <ListItemIcon>
                            <UploadIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            {t('Import URLs')}
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={(e) => {
                        handleClose()
                        handleAdd();
                    }}
                              sx={{fontSize: '0.875rem'}}>
                        <ListItemIcon>
                            <PostAddIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            {t('New Paper')}
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={(e) => {
                        handleClose()
                        handleDownloadCSV();
                    }}
                              sx={{fontSize: '0.875rem'}}>
                        <ListItemIcon>
                            <GetAppIcon/>
                        </ListItemIcon>
                        <ListItemText>
                            {t('Download CSV')}
                        </ListItemText>
                    </MenuItem>

                </MenuList>
            </Popover>
        </React.Fragment>
    );
}

export default HeaderActions;