import React from 'react';
import Grid from '@mui/material/Grid';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';

import LabeledChip from 'components/elements/LabeledChip';

const colors = ['#daf7ca', '#f7caca', '#f7e0ca','#cae7f7']

const statusIcon = (status) => {
    switch (status) {
        case 'SUCCESS':
            return <CheckCircleIcon fontSize={"small"} sx={{color: '#00c360'}}/>;
        case 'FAILED':
            return <WarningAmberIcon fontSize={"small"} sx={{color: '#df812e'}}/>;
        case 'PARTIAL':
            return <AutoModeIcon fontSize={"small"} sx={{color: '#EED437'}}/>;
        case 'NOT_EXTRACTED':
            return <PlagiarismOutlinedIcon fontSize={"small"} sx={{color: '#0894d3'}}/>;
        default:
            return <></>;
    }
};

const getEvidences = (evidences) => {
    const list = []
    if (evidences && evidences.length > 0) {
        evidences.forEach(provider => {
            if (!list.some(item => item.type === provider.extraction_type)) {
                let status = getStatus(evidences, provider.extraction_type)
                list.push({type: provider.extraction_type, status})
            }
        })

        return list.map(evidence => {
            return <Grid item><LabeledChip icon={statusIcon(evidence.status)} label={(getLabel(evidence.type))}
                                           iconColor={getIconColor(evidence.status)}
                                           backgroundColor={getColor(evidence.status)}/></Grid>
        })

    } else {
        return <Grid item><LabeledChip label={'Pending Extraction'}
                                       icon={statusIcon('NOT_EXTRACTED')}
                                       iconColor={getIconColor('NOT_EXTRACTED')}
                                       backgroundColor={getColor('NOT_EXTRACTED')}
                                       /></Grid>
    }
}

const getColor = (status) => {
    switch (status) {
        case 'SUCCESS':
            return colors[0];
        case 'FAILED':
            return colors[1];
        case 'NOT_EXTRACTED':
            return colors[3];
        case 'PARTIAL':
            return colors[2];
        default:
            return '';
    }
}

const getIconColor = (status) => {
    switch (status) {
        case 'SUCCESS':
            return '#00c360';
        case 'FAILED':
            return '#df812e';
        case 'NOT_EXTRACTED':
            return '#0894d3';
        case 'PARTIAL':
            return '#EED437';
        default:
            return '';
    }
}

const getLabel = (source) => {
    switch (source) {
        case 'GPT':
            return 'GPT';
        case 'CHAT_GPT':
            return 'GPT';
        case 'CHAT_GPT_KOR':
            return 'GPT RA V1';
        case 'MANUAL':
            return 'Manual';
        case 'CURATED':
            return 'Curated';
        case "CHAT_GPT_RA_V2":
            return "GPT RA V2"
        case "CHAT_GPT_RA_V3_1":
            return "GPT RA V3.1"
        case "CHAT_GPT_RA_V3_2":
            return "GPT RA V3.2"
        default:
            return source;
    }
}

const statusTooltip = (status) => {
    switch (status) {
        case 'EXTRACTION_SUCCESS':
            return 'Extraction Success';
        case 'EXTRACTION_FAILED':
            return 'Extraction Failed';
        case 'EXTRACTION_PARTIAL':
            return 'Extraction Partial';
        case 'NOT_EXTRACTED':
            return 'Pending Extraction';
        default:
            return '';
    }
};

const getStatus = (evidences, type) => {
    let successCount = 0
    let partialCount = 0
    let errorCount = 0

    evidences.forEach(provider => {
        if (provider.extraction_type === type) {
            if (provider.status === 'SUCCESS') {
                successCount++;
            }
            if (provider.status === 'FAILED') {
                errorCount++;
            }
            if (provider.status === 'PARTIAL') {
                partialCount++;
            }

        }
    })

    if (successCount > 0) {
        return 'SUCCESS'
    } else if (partialCount > 0) {
        return 'PARTIAL'
    } else if (errorCount > 0) {
        return 'FAILED'
    } else {
        return 'NOT_EXTRACTED'
    }
}

export {statusIcon,getEvidences,statusTooltip,getLabel}