import {useMutation} from '@tanstack/react-query';
import api from 'services/api';

const useClassifierModelDelete = () => {
    const deleteModel = async (modelId) => {
        try {
            const trials = await api.delete(
                `api/v1/classifiers/models/${modelId}`
            );

            const data = await trials.json();

            return data.data;
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useMutation({
        mutationFn: ({modelId}) => deleteModel(modelId),
    });
};

export default useClassifierModelDelete;