import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';
import alpha from "color-alpha";

const StackedBarChart = ({title, maxHeight = '400px', data = []}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'column'
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                title: {
                    text: null
                },
                gridLineWidth: 1,
                lineWidth: 0
            },
            yAxis: {
                min: 0,
                labels: {
                    overflow: 'justify'
                },
                title: {
                    text: null
                },
                gridLineWidth: 0
            },
            plotOptions: {
                column: {
                    borderRadius: '50%',
                    dataLabels: {
                        enabled: true
                    },
                    grouping: false,
                },
                series: {
                    animation: false
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true,
                enabled: false
            },
            credits: {
                enabled: false
            },
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {


            const categories = []
            const total = []
            const overlap = []
            for (const value of data) {
                categories.push(value.vertical)
                total.push({
                    y: value.count,
                    color: alpha('#3401af', .3)
                })
                overlap.push({
                    y: value.overlap_count,
                    color: alpha('#3401af', .5)
                })
            }

            const series = [
                {
                    name: 'Total manual validations',
                    data: total
                },
                {
                    name: 'Overlap of manual validation with classified',
                    data: overlap
                }
            ]

            setOptions({
                ...charOptions(),
                series: series,
                xAxis: {
                    categories
                }
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', maxHeight,minHeight:maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default StackedBarChart;
