import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {Card, Chip} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import styled from '@mui/system/styled';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import PageContainer from 'components/elements/PageContainer';
import GraphCard from 'components/elements/GraphCard';
import CustomTooltip from 'components/elements/CustomTooltip';
import useClassifierModel from 'hooks/classifiers/useClassifierModel';
import CustomButton from 'components/elements/CustomButton';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import useClassifierModelDelete from 'hooks/classifiers/useClassifierModelDelete';
import {ROUTE_INBOX_MODEL_PERFORMANCE} from 'pages/constants';

import PrcChart from './prc-chart';
import RocChart from './roc-chart';
import ConfusionMatrixChart from './confusion-matrix';
import FeatureImportanceChart from './feature-importance-chart';

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({theme}) => ({
    fontSize: 'small',
}));
const ModelOverview = () => {
    const {t} = useTranslation();
    const {modelId} = useParams();

    const {data, status} = useClassifierModel({modelId})

    const [model, setModel] = useState(null)

    useEffect(() => {
        if (data) {
            setModel(data.data)
        }
    }, [data])

    return (
        <PageContainer pageTitle={model && model.model_name}
                       pageSubTitle={model && t('inbox.modelPerformance.overview.pageSubTitle')}>
            <ModelOverviewPage model={model} status={status}/>
        </PageContainer>
    );
};

const ConfigHeader = ({title}) => {
    return <Typography>{title} <span><CustomTooltip title={title}> <span
        style={{verticalAlign: '-0.1em'}}><HelpOutlineIcon fontSize="1.6rem"
                                                           sx={{'&.MuiSvgIcon-root': {color: '#666666'}}}/></span></CustomTooltip></span></Typography>
}

const SummaryCard = ({
                         title, count, onClick = () => {
    }
                     }) => {
    return <Grid item xs={4} sx={{height:'fit-content'}}>
        <Card sx={{padding: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: '1rem',
                        fontWeight: '700',
                        textAlign: 'center',
                        color: '#797979',
                        textTransform: 'capitalize'
                    }}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography onClick={onClick} sx={{
                        fontSize: '2.25rem',
                        fontWeight: '700',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}>{count}</Typography>
                </Grid>
            </Grid>
        </Card>
    </Grid>
}

const ModelOverviewPage = ({model, status, containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [modelDeleting, setModelDeleting] = useState(false);

    const {mutateAsync} = useClassifierModelDelete();

    function isObject(item) {
        return (typeof item === "object" && !Array.isArray(item) && item !== null);
    }

    const convertJson = (item) => {
        if (isObject(item)) {
            return item
        } else if (typeof item === 'string') {
            return JSON.parse(item)
        } else {
            return null
        }
    }

    const handleGoToPerformance = ()=>{
        navigate({
            pathname: ROUTE_INBOX_MODEL_PERFORMANCE,
        });
    }

    const handleDeleteOpen = (event) => {
        event.stopPropagation();
        setModelDeleting(true)
    }

    const handleDeleteClose = () => {
        setModelDeleting(false)
    }

    const handleDelete = (paperId) => {
        mutateAsync({modelId:model['model_id']}).then(() => {
            handleDeleteClose();
            handleGoToPerformance()
        });
    }

    if (status==='pending' || !model){
        return <Grid container justifyContent="center" alignItems="center" sx={{width: '100%', height: `calc(${containerHeight} - 80px)`}}>
            <Grid item>
                <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70} width={70}/>
            </Grid>
        </Grid>
    }

    return (
        <Grid container spacing={2} sx={{padding: '0.5em !important', overflow: 'auto', height: containerHeight - 4}}>

            <Grid item container xs={12} spacing={1} justifyContent={'space-between'} sx={{'&.MuiGrid-root': {width: 'unset'}}}>
                <Grid item>
                    <CustomButton disableRipple onClick={handleGoToPerformance}>Model Performance</CustomButton>
                </Grid>
                <Grid item>
                    <CustomTooltip title={"Delete model"}>
                        <IconButton onClick={handleDeleteOpen}>
                           <DeleteOutlineIcon/>
                        </IconButton>
                    </CustomTooltip>
                </Grid>
            </Grid>

            <Grid item xs={8} container spacing={2} sx={{height:'fit-content'}}>
               <Grid item xs={12} container spacing={2}>
                    <SummaryCard title={'Accuracy'} count={model.accuracy.toFixed(2)}/>
                    <SummaryCard title={'F1 Score'} count={model.f1_score.toFixed(2)}/>
                    <SummaryCard title={'Precision'} count={model.precision.toFixed(2)}/>
                    <SummaryCard title={'Recall'} count={model.recall.toFixed(2)}/>
                    <SummaryCard title={'ROC AUC'} count={model.roc_auc.toFixed(2)}/>
                    <SummaryCard title={'PRC AUC'} count={model.prc_auc.toFixed(2)}/>
                </Grid>

                <Grid item xs={6}>
                    <GraphCard title={'ROC Curve'}>
                        <RocChart maxHeight={'450px'} data={convertJson(model.roc_curve)}/>
                    </GraphCard>
                </Grid>

                <Grid item xs={6}>
                    <GraphCard title={'PRC Curve'}>
                        <PrcChart maxHeight={'450px'} data={convertJson(model.prc_curve)}/>
                    </GraphCard>
                </Grid>

                <Grid item xs={12}>
                    <GraphCard title={'Confusion Matrix'}>
                        <ConfusionMatrixChart maxHeight={'300px'} data={convertJson(model.confusion_matrix)}/>
                    </GraphCard>
                </Grid>

                <Grid item xs={12}>
                    <GraphCard title={'Feature Importance'}>
                        <FeatureImportanceChart maxHeight={Object.keys(convertJson(model.feature_importance)).length*25} data={convertJson(model.feature_importance)}/>
                    </GraphCard>
                </Grid>

                {/*<Grid xs={6} item>*/}
                {/*    <GraphCard title={'Classified relevant vs Evidence extracted'}>*/}
                {/*        <StackedBarChart data={[]} categories={['HE', 'HEJ']} series={[*/}
                {/*            {*/}
                {/*                name: 'Classified relevant',*/}
                {/*                data: [*/}
                {/*                    {*/}
                {/*                        y: 23,*/}
                {/*                        color: alpha('#3401af', .5)*/}
                {/*                    }, {*/}
                {/*                        y: 12,*/}
                {/*                        color: alpha('#df812e', .5)*/}
                {/*                    }*/}
                {/*                ]*/}
                {/*            },*/}
                {/*            {*/}
                {/*                name: 'Evidence extracted',*/}
                {/*                data: [*/}
                {/*                    {*/}
                {/*                        y: 18,*/}
                {/*                        color: alpha('#3401af', .5)*/}
                {/*                    }, {*/}
                {/*                        y: 10,*/}
                {/*                        color: alpha('#df812e', .5)*/}
                {/*                    },*/}
                {/*                ]*/}
                {/*            }]}/>*/}
                {/*    </GraphCard>*/}
                {/*</Grid>*/}

                {/*<Grid xs={6} item>*/}
                {/*    <GraphCard title={'Manually validated papers vs Correctly classified papers'}>*/}
                {/*        <StackedBarChart maxHeight={'300px'} categories={['HE', 'HEJ']} series={[*/}
                {/*            {*/}
                {/*                name: 'Manually validated papers',*/}
                {/*                data: [*/}
                {/*                    {*/}
                {/*                        y: 34,*/}
                {/*                        color: alpha('#3401af', .5)*/}
                {/*                    }, {*/}
                {/*                        y: 50,*/}
                {/*                        color: alpha('#df812e', .5)*/}
                {/*                    }*/}
                {/*                ]*/}
                {/*            },*/}
                {/*            {*/}
                {/*                name: 'Correctly classified papers',*/}
                {/*                data: [*/}
                {/*                    {*/}
                {/*                        y: 18,*/}
                {/*                        color: alpha('#3401af', .5)*/}
                {/*                    }, {*/}
                {/*                        y: 30,*/}
                {/*                        color: alpha('#df812e', .5)*/}
                {/*                    },*/}
                {/*                ]*/}
                {/*            }]}/>*/}
                {/*    </GraphCard>*/}
                {/*</Grid>*/}
            </Grid>

            <Grid item xs={4}>
                <GraphCard title={'Train parameters'}>
                    <Grid xs={12} item container spacing={2}>
                        <Grid xs={12} item>
                            <ConfigHeader title={'Verticals'}/>
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            {model['verticals'].map((field) => {
                                return <Grid item><Chip
                                    label={
                                        <Box sx={{ whiteSpace: "normal" }}>
                                            {field.vertical_name}
                                        </Box>
                                    }
                                    sx={{
                                        height: "auto",
                                    }}
                                    key={`verticals-${field.vertical}`}
                                />
                                </Grid>
                            })}
                        </Grid>

                        <Grid xs={12} item>
                            <ConfigHeader title={'Table search terms'}/>
                        </Grid>
                       <Grid item xs={12} container spacing={1}>
                            {model['train_configurations'] && Object.entries(model['train_configurations']['table_search_params']).map(([key, value], index) => {
                                return <Grid item><Chip
                                    label={
                                        <Box sx={{ whiteSpace: "normal" }}>
                                            {`${key} : ${value}`}
                                        </Box>
                                    }
                                    sx={{
                                        height: "auto",
                                    }}
                                    key={`table_search_params-${index}`}
                                />
                                </Grid>
                            })}
                        </Grid>

                        <Grid xs={12} item>
                            <ConfigHeader title={'Keyword search terms'}/>
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            {model['train_configurations'] && Object.entries(model['train_configurations']['keyword_search_params']).map(([key, value], index) => {
                                return <Grid item><Chip
                                    label={
                                        <Box sx={{ whiteSpace: "normal" }}>
                                            {`${key} : ${value}`}
                                        </Box>
                                    }
                                    sx={{
                                        height: "auto",
                                    }}
                                    key={`keyword_search_params-${index}`}
                                />
                                </Grid>
                            })}
                        </Grid>

                        <Grid xs={12} item>
                            <ConfigHeader title={'Search phrases'}/>
                        </Grid>
                         <Grid item xs={12} container spacing={1}>
                            {model['train_configurations'] && Object.entries(model['train_configurations']['search_phrases']).map((field, index) => {
                                return <Grid item><Chip
                                    label={
                                        <Box sx={{ whiteSpace: "normal" }}>
                                            {field}
                                        </Box>
                                    }
                                    sx={{
                                        height: "auto",
                                    }}
                                    key={`search-phrase-${index}`}
                                />
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </GraphCard>
            </Grid>

            <>{modelDeleting && <ConfirmationDialog open={modelDeleting}
                                                      onConfirm={() => handleDelete(model['model_id'])}
                                                      title={`Delete paper`}
                                                      context={`Are you sure want to delete model: ${model['model_name']}?`}
                                                      confirmText={'Delete'}
                                                      onClose={handleDeleteClose}/>}</>

        </Grid>
    );
};

export default ModelOverview;
