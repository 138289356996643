import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, FormHelperText, FormLabel} from '@mui/material';
import {Formik} from 'formik';
import Typography from '@mui/material/Typography';

import useExtractionEligibility from 'hooks/evidences/useExtractionEligibility';

import {getLabel} from '../utils';
import FormControl from "@mui/material/FormControl";


const ExtractEvidenceForm = ({
                                 formRef,
                                 onSubmit,
                                 customGpts = [],
                                 selectedAll = false,
                                 selected = [],
                                 count = 0,
                                 vertical
                             }) => {

    const {t} = useTranslation();
    const [selectedExtractionType, setSelectedExtractionType] = useState(['CHAT_GPT_RA_V2']);
    const {data} = useExtractionEligibility({
        vertical,
        selectedAll,
        papers: selected,
        extractionTypes: selectedExtractionType
    });
    const [paperCount, setPaperCount] = useState([]);

    useEffect(() => {

        if (data) {
            setPaperCount(data.data ?? [])
        }

    }, [data])

    const validationSchema = Yup.object().shape({
        extraction_types: Yup.array()
            .min(1, t('Select at least 1 extraction type'))
            .required(t('At least 1 extraction type is required')),
    });

    const handleTypeChange = (sourceId, setFieldValue) => {
        let selected = [...selectedExtractionType]
        if (selectedExtractionType.includes(sourceId)) {
            selected = selected.filter(id => id !== sourceId)
        } else {
            if (selectedExtractionType.length < 3) {
                selected.push(sourceId)
            }
        }
        setSelectedExtractionType(selected)
        setFieldValue('extraction_types', selected);
    }

    const getExtractedCount = (type) => {
        let i = paperCount.findIndex(s => s.extraction_type === type);
        if (i >= 0) {
            return paperCount[i].extracted
        } else {
            return 0
        }
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    extraction_types: ['CHAT_GPT_RA_V2'],
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{height: 'fit-content'}} container spacing={2}>

                            <Grid item xs={12} container alignItems={'flex-start'} spacing={1}>

                                <Grid item xs={12} container justifyContent={'space-between'} alignItems={'flex-start'}
                                      spacing={1}>
                                    <Grid item>
                                        <FormControl variant="outlined" fullWidth required
                                                     error={Boolean(touched.extraction_types && errors.extraction_types)}>
                                            <FormLabel component="legend" sx={{fontSize: '0.875rem', fontWeight: 600}}>Extract
                                                using</FormLabel>
                                            <FormHelperText
                                                sx={{'&.MuiFormHelperText-root': {marginLeft: 0}}}
                                                error={Boolean(touched.extraction_types && errors.extraction_types)}>
                                                {touched.extraction_types && errors.extraction_types}
                                            </FormHelperText>
                                            <Grid item sx={{paddingRight: '0.25em'}} xs={12} container>
                                                {customGpts.map(gpt => (
                                                    <Grid item xs={12} container alignItems={'center'}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedExtractionType?.includes(gpt.value)}
                                                                        value={gpt.value}
                                                                        disabled={!gpt.active}
                                                                        onChange={() => handleTypeChange(gpt.value, setFieldValue, values.extraction_types)}
                                                                        name={gpt.value}/>
                                                                }
                                                                sx={{width: '100%'}}
                                                                label={getLabel(gpt.name)}
                                                            />
                                                        </Grid>
                                                        {selectedExtractionType?.includes(gpt.value) && <Grid item>
                                                            <Typography sx={{
                                                                color: (count - getExtractedCount(gpt.value)) > 0 ? '#4caf50' : '#ff9800',
                                                                fontSize: '0.875rem'
                                                            }}>Evidence
                                                                has been extracted
                                                                for {getExtractedCount(gpt.value)} out
                                                                of {count} papers. Evidence extraction
                                                                will be performed on the
                                                                remaining {count - getExtractedCount(gpt.value)} papers.</Typography>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default ExtractEvidenceForm;