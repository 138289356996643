import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import usePaperCreate from 'hooks/papers/usePaperCreate';
import LabeledChip from 'components/elements/LabeledChip';
import CustomDrawer from 'components/elements/CustomDrawer';

const PaperItem = ({name, value, enableClipboard = false, content}) => {
    return <Grid container item xs={6} alignItems={'flex-start'} sx={{height: 'fit-content'}}>
        <Grid item xs={12}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: '700',
                color: '#797979',
                textTransform: 'capitalize'
            }}>{name}</Typography>
        </Grid>
        <Grid item xs={12}>
            {!content ? <Typography sx={{fontSize: '0.875rem'}}>{value ? value : 'N/A'}
                {enableClipboard && value && <span style={{paddingLeft: '0.5em', cursor: 'pointer'}}>
                     <CopyToClipboard text={value}>
                <IconButton sx={{padding: '0.1em'}}>
                    <ContentCopyIcon sx={{fontSize: '0.875rem'}}/>
                </IconButton>
                     </CopyToClipboard>
                </span>
                }
            </Typography> : content}
        </Grid>
    </Grid>
}

const ViewPaperDrawer = ({open, handleClose}) => {
    const {t} = useTranslation();

    const {mutateAsync, status} = usePaperCreate();

    const getType = ({type, key}) => {
        switch (type) {
            case 'RELEVANT':
                return <LabeledChip label={t('Relevant')} backgroundColor={'#daf7ca'}/>;
            case 'NOT_RELEVANT':
                return <LabeledChip label={t('Not relevant')} backgroundColor={'#efefcb'}/>;
            case 'NOT_EVALUATED':
                return key ? <LabeledChip label={t('Not Validated')} backgroundColor={'#ffbd6a'}/> :
                    <LabeledChip label={t('Not Evaluated')} backgroundColor={'#c8edfd'}/>;
            default:
                return type
        }
    }

    const getSource = (source) => {
        switch (source) {
            case 'SEMANTIC_SCHOLAR':
                return 'Semantic scholar';
            case 'GOOGLE_SCHOLAR':
                return 'Google scholar';
            case 'MANUAL':
                return 'Manual';
            default:
                return '';
        }
    }

    return (
        <React.Fragment>
            <CustomDrawer open={open} onclose={handleClose}>
                <Box sx={{width: '50vw', padding: 4}}>
                    <Stack direction='row' spacing={1} justifyContent='flex-end'>
                        <IconButton
                            aria-label='close'
                            onClick={handleClose}
                            sx={{
                                backgroundColor: '#333333',
                                padding: '4px',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                            }}
                        >
                            <CloseIcon sx={{color: '#ffffff', fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Stack>
                    <Grid container spacing={4}>
                        <Grid item xs={11} container spacing={2} sx={{paddingBottom: '32px !important'}}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography sx={{
                                    fontWeight: '700',
                                    fontSize: '1.2rem'
                                }}>{t('Paper details')}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{paddingTop: '4px !important'}}>
                                <Divider sx={{borderBottom: '2px solid black;', width: '100%'}}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} container spacing={2} sx={{
                            overflowY: 'auto',
                            paddingTop: '0 !important',
                            overflowX: 'hidden', maxHeight: 'calc(100vh - 144px)'
                        }}>
                            <PaperItem name={'Name'} value={open['paper_name']} enableClipboard/>
                            <PaperItem name={'Search phrase'} value={open['search_phrase']['search_phrase']}
                                       enableClipboard/>
                            <PaperItem name={'Source'} value={getSource(open['source_provider'])}/>
                            {/*<PaperItem name={'Download path'} value={open['paper_downloaded_path']} enableClipboard/>*/}
                            <PaperItem name={'Published year'} value={open['published_year']}/>
                            <PaperItem name={'Citation count'} value={open['citation_count']}/>
                            <PaperItem name={'Abstract'} value={open['abstract']} enableClipboard/>
                            <PaperItem name={'APA citation'} value={open['apa_citation']} enableClipboard/>
                            <PaperItem name={'Manually validated'} content={getType({
                                type: open['relevant_paper_manually_validated'],
                                key: 'manual'
                            })}/>
                            <PaperItem name={'Model prediction'}
                                       content={getType({type: open['relevant_paper_model_prediction']})}/>
                            <PaperItem name={'Prediction score'}
                                       value={open['relevant_paper_model_prediction_score'] ? open['relevant_paper_model_prediction_score'] : 'N/A'}/>
                        </Grid>
                    </Grid>
                </Box>
            </CustomDrawer>
        </React.Fragment>
    );
};

export default ViewPaperDrawer;