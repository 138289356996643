import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import {saveAs} from 'file-saver';

import PageContainer from 'components/elements/PageContainer';
import {
    ROUTE_PIPELINES_CLASSIFICATION_SESSIONS,
    ROUTE_PIPELINES_DOWNLOAD_SESSIONS,
    ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS
} from 'pages/constants';
import usePaperCSVDownload from 'hooks/papers/usePaperCSVDownload';

import Filters from './filters';
import CreatePaperDialog from './createPaper/create-paper-dialog';
import CreateSearchDialog from './createSearch/create-search-dialog';
import TriggerClassifierDialog from './triggerClassifier/trigger-classifier-dialog';
import ImportPaperDialog from './importPaperWithPDF/import-paper-dialog';
import PaperList from './paper-list';
import TrainClassifierDialog from './trainClassifier/train-classifier-dialog';
import HeaderActions from './HeaderActions';

const InboxExplore = () => {
    const {t} = useTranslation();

    return (
        <PageContainer pageTitle={t('evidence.explore')} pageSubTitle={t('Manage Downloaded Papers')}>
            <InboxExplorePage/>
        </PageContainer>
    );
};

const predictionStatues = ['RELEVANT', 'NOT_RELEVANT', 'NOT_EVALUATED']

const InboxExplorePage = ({containerHeight}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [paperAdding, setPaperAdding] = useState(false);
    const [searchAdding, setSearchAdding] = useState(false);
    const [triggering, setTriggering] = useState(false);
    const [importing, setImporting] = useState(false);
    const [training, setTraining] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [total, setTotal] = useState(0);

    const {mutateAsync: downloadCSV} = usePaperCSVDownload();

    const selectedVertical = useSelector((state) => state.app.vertical);
    const filters = useSelector((state) => state.app.papersFilters);

    const [sort, setSort] = useState({
        relevant_paper_model_prediction_score: 'desc',
        citation_count: 'desc',
        published_year: 'desc',
        created_at: 'desc',
        current: 'created_at'
    });

    const handleAdd = () => {
        setPaperAdding(true)
    }
    const handleAddPaperClose = () => {
        setPaperAdding(false)
    }
    const handleAddSearch = () => {
        setSearchAdding(true)
    }
    const handleAddSearchClose = ({navigate = false}) => {
        setSearchAdding(false);
        if (navigate) {
            handleGoToDownloadSessions();
        }
    }
    const handleTrigger = () => {
        setTriggering(true)
    }
    const handleTrain = () => {
        setTraining(true)
    }
    const handleImportWithPDF = () => {
        setImporting(true)
    }

    const handleTriggerClose = ({navigate = false}) => {
        setTriggering(false)
        if (navigate) {
            handleGoToClassificationSessions();
        }
    }
    const handleImportClose = ({navigate = false}) => {
        setImporting(false)
        if (navigate) {
            handleGoToDownloadSessions();
        }
    }

    const handleTrainClose = ({navigate = false}) => {
        setTraining(false)
        if (navigate) {
            handleGoToModelTrainingSessions();
        }
    }

    const handleRefresh = () => {
        setRefresh(Math.random())
    }

    const handleGoToDownloadSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_DOWNLOAD_SESSIONS
        });
    };

    const handleGoToModelTrainingSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_MODEL_TRAINING_SESSIONS
        });
    };

    const handleGoToClassificationSessions = () => {
        navigate({
            pathname: ROUTE_PIPELINES_CLASSIFICATION_SESSIONS
        });
    };

    const handleTotal = (value) => {
        setTotal(value)
    }

    const handleDownloadCSV = async () => {
        try {
            downloadCSV({ filters, sort, vertical: selectedVertical }).then((res) => {
                saveAs(res.data, res.filename);
            });
        } catch (error) {
            console.error("Download failed:", error);
        }
    }

    useEffect(() => {
        setRefresh(0)
    }, [])

    return (
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1} sx={{padding: '0 1em !important'}}>

            <Grid item container xs={12} alignItems={'center'}>
                <Filters/>
            </Grid>

            <HeaderActions
                handleAdd={handleAdd}
                handleAddSearch={handleAddSearch}
                handleRefresh={handleRefresh}
                handleTrain={handleTrain}
                handleTrigger={handleTrigger}
                handleDownloadCSV={handleDownloadCSV}
                handleImportWithPDF={handleImportWithPDF}
            />

            <Grid item xs={12}>
                <PaperList height={containerHeight - 164}
                           refresh={refresh}
                           selectedVertical={selectedVertical}
                           filters={filters}
                           sort={sort}
                           handleSort={setSort}
                           handleTotal={handleTotal}
                />
            </Grid>
            <Grid item xs={12} container justifyContent={'flex-end'}>
                <Grid item>
                    <Typography sx={{fontWeight:600,fontSize:'0.875rem'}}>Paper Count: {total}</Typography>
                </Grid>
            </Grid>
            {paperAdding &&
                <CreatePaperDialog open={paperAdding} handleClose={handleAddPaperClose} height={containerHeight}/>}
            {searchAdding &&
                <CreateSearchDialog open={searchAdding} handleClose={handleAddSearchClose} height={containerHeight}/>}
            {triggering &&
                <TriggerClassifierDialog open={triggering} handleClose={handleTriggerClose} height={containerHeight} total={total}/>}
            {importing &&
                <ImportPaperDialog open={importing} handleClose={handleImportClose} height={containerHeight}/>}
            {training &&
                <TrainClassifierDialog open={training} handleClose={handleTrainClose} height={containerHeight}/>}
        </Grid>
    );
}

export default InboxExplore;
